import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import dotAnimation from "./img/dot-loader.json"; // Path to your Lottie JSON file
import logo from "./img/logo.svg"; // Path to your Lottie JSON file

const Loader = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}>
            <img
                src={logo}
                alt="fabx"
                className="logo"
                style={{ position: "absolute", top: "37%" }}
            />

            <Player
                autoplay
                loop
                src={dotAnimation}
                style={{ height: "300px", width: "300px" }}
                color="black"
            />
        </div>
    );
};

export default Loader;
